.site-layout-sub-header-background {
  box-shadow: rgb(255 255 255 / 90%) 0rem 0rem 0.0625rem 0.0625rem inset,
    rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem;
  backdrop-filter: saturate(200%) blur(1.875rem);
  background-color: #fffc;
  border: none !important;
  padding-left: 16px !important;
  padding-right: 24px;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 5;
  left: 0;
  right: 0;
  top: 0;
  height: 50px;

  > .rightSide {
    position: absolute;
    right: 24px;
  }
}

.ant-layout.ant-layout-has-sider {
  margin-top: 80px;
}

.logo {
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  margin: 0px 8px;
}

.ant-layout-header {
  line-height: 10px !important;
}

.ant-layout-header .ant-menu {
  line-height: 49px !important;
  width: calc(100vw - 350px);
}
