$mobile-width: 768px;
$tablet-width: 1280px;

@mixin mobile {
  @media (max-width: #{$mobile-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin mobileLandscape {
  @media only screen and (max-height: #{$mobile-width - 1px}) and (orientation: landscape) {
    @content;
  }
}

@mixin mobilePortrait {
  @media only screen and (max-height: #{$mobile-width - 1px}) and (orientation: portrait) {
    @content;
  }
}
