
.flexlayout__layout {
    top: 50px !important;

    &.flexlayout__layout_newPage {
        top: 0px !important;
    }

    &.flexlayout__layout_newPage .flexlayout__tab {
        background-color: transparent;
    }

    &.flexlayout__layout_newPage .flexlayout__tabset {
        background-color: transparent;
    }
}