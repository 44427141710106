@import "./mixin/responsive";

body {
  background-color: #f0f2f5;
}
#components-layout-demo-responsive .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  box-shadow: rgb(255 255 255 / 90%) 0rem 0rem 0.0625rem 0.0625rem inset, rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem;
  backdrop-filter: saturate(200%) blur(1.875rem);
  background-color: #fffc;
  border: none !important;
  padding-left: 16px !important;
  padding-right: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 2;
  left: 0;
  right: 0;
  top: 0;

  > .rightSide {
    display: flex;
    align-items: center;
  }

  @include tablet {
    justify-content: start;
  }

  @include mobile {
    justify-content: start;
  }
}

.dockLayoutHeader {
  box-shadow: rgb(255 255 255 / 90%) 0rem 0rem 0.0625rem 0.0625rem inset, rgb(0 0 0 / 5%) 0rem 1.25rem 1.6875rem 0rem;
  backdrop-filter: saturate(200%) blur(1.875rem);
  background-color: #fffc;
  border: none !important;
  padding-left: 16px !important;
  padding-right: 24px;
  display: flex;
  align-items: center;
  z-index: 5;
  left: 0;
  right: 0;
  top: 0;
  height: 50px;
  background: white !important;

  > .rightSide {
    position: absolute;
    right: 24px;
  }
}

.ant-layout.ant-layout-has-sider {
  margin-top: 80px;
}

.site-layout-background {
  padding: 0 !important;
}

.ant-layout.ant-layout-has-sider {
  flex-direction: row;
  padding-right: 16px;
  background: none !important;

  @include mobile {
    padding-left: 16px;
  }
}

.ant-layout-sider-zero-width-trigger {
  position: fixed;
  top: 12px;
  left: 4px;
  z-index: 1;
  width: 36px;
  height: 42px;
  color: #000;
  font-size: 18px;
  line-height: 42px;
  text-align: center;
  background: #ffffff;
  border-radius: 0 2px 2px 0;
  cursor: pointer;
  transition: background 0.3s ease;
}

.ant-layout-sider {
  border-radius: 8px;
  margin: 0 16px;
  background: linear-gradient(195deg, #42424a, #191919) !important;
  height: calc(100vh - 100px);
}

.logo {
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  margin: 8px;

  @include mobile {
    font-size: 16px;
    margin-left: 24px;
  }
}

.menüTitle {
  display: flex;
  flex-direction: column;
  > p {
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
    font-size: 28px;
    margin: 12px 0 0;
  }
  > small {
    height: 50px;
    display: flex;
    color: rgba(255, 255, 255, 0.637);
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-bottom: 1px solid #fff3;
    margin: 0 16px;
  }
}
.copyright {
  position: absolute;
  bottom: 12px;
  color: white;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
}

.ant-modal {
  top: 80px;
}

.ant-input-number-prefix {
  font-weight: bold;
}

.ant-form-item-required {
}
.ant-form-item-explain-error {
  font-size: 12px !important;
}

.loginForm {
  .ant-form-item {
    margin-bottom: 8px !important;
  }
  .ant-form-item-label {
    text-align: left !important;
  }
}

.text-center {
  text-align: center;
}

.ant-form-item-has-error {
  .react-tel-input .form-control {
    border-color: #ff4d4f !important;
    box-shadow: none !important;
  }
}

.react-tel-input .form-control {
  width: 100% !important;
  border-radius: 2px !important;
  &:focus {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
    border-right-width: 1px !important;
    outline: 0;
  }

  &:hover {
    border-color: #40a9ff;
    border-right-width: 1px !important;
  }
}
.ant-modal-footer > span {
  margin: 0 8px !important;
}

.distance-legend {
  right: 4px !important;
  bottom: 70px !important;
}

.customSelect {
  .ant-select-selector {
    background-color: #00000077 !important;
    color: white !important;
    
  }
  .ant-select-arrow {
    color: white !important;
  }
}
