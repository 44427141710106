@import "./mixin/responsive";
@import "./antOverride.scss";

@keyframes changeBg {
  0% {
    background-image: url(./img/1.jpg);
  }
  20% {
    background-image: url(./img/2.jpg);
  }
  40% {
    background-image: url(./img/3.jpg);
  }
  60% {
    background-image: url(./img/4.jpg);
  }
  80% {
    background-image: url(./img/5.jpg);
  }
  100% {
    background-image: url(./img/1.jpg);
  }
}

.loginPageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  @include tablet {
    height: 100%;
  }

  @include mobile {
    height: 100%;
  }

  > div {
    width: 70%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 70%;
    display: flex;
    align-items: flex-end;
    transition: box-shadow ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    position: relative;
    border: 0px solid #00000020;
    border-radius: 24px;
    box-shadow: rgb(0 0 0 / 10%) 0rem 0.25rem 0.375rem -0.0625rem,
    rgb(0 0 0 / 6%) 0rem 0.125rem 0.25rem -0.0625rem;
    overflow: hidden;

    animation: changeBg 8s infinite 0.2s;

    > div {
      width: 100%;
    }

    @include tablet {
      width: 100%;
      height: 100%;
    }

    @include mobile {
      width: 100%;
    }

    > form {
      background-color: #ffffffd9;
      width: 380px;
      padding: 0 16px;

      @include tablet {
        width: 100%;
      }

      @include mobile {
        width: 100%;
        height: 100vh;
      }
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
    }
  }
}

.responsiveBr {
  @include tablet {
    display: none;
  }
}

.copyright {
  position: absolute;
  bottom: 12px;
  color: #000;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;

  @include mobile {
    bottom: 4px;
  }
}

.helpLink {
  all: unset;
  cursor: pointer;

  position: absolute;
  bottom: 10px;
  color: #000;
  right: 20px;
  text-align: center !important;

  @include mobile {
    bottom: 4px;
  }
}

.flexlayout__splitter {
  z-index: -99999 !important;
  cursor: pointer !important;
}

.cesium-context {
  font-size: 1.1em;
  position: absolute;
  width: 200px;
  height: auto;
  padding: 5px 0px;
  border-radius: 5px;
  top: 10;
  left: 10;
  background-color: #fff;
  box-shadow: 0 12px 15px 0 rgba(0, 0, 0, 0.24);
  color: #333;
  z-index: 99999;
  .context_item {
    height: 32px;
    line-height: 32px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &:hover {
      background-color: #ddd;
    }
    .inner_item {
      margin: 0px 10px;
      i {
        margin: 0 5px 0 0;
        font-weight: bold;
      }
    }
  }
  .context_hr {
    height: 1px;
    border-top: 1px solid #bbb;
    margin: 3px 10px;
  }
}

.ant-design-draggable-modal .small-draggable {
  & .ant-table-wrapper .ant-table.ant-table-small {
    font-size: 12px;
  }

  & .ant-modal-content {
    padding: 5px;
  }

  & .ant-modal-header {
    margin-bottom: 0;
  }

  & .ant-modal-body {
    padding: 0 20px;
  }

  & .ant-modal-footer {
    padding: 10px;
  }

  & .ant-btn {
    font-size: 10px;
    padding: 0 10px;
  }
}
