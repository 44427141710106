@import "../../../assets/mixin/responsive";

.portraitModeModal {
    @include mobileLandscape {
      display: none !important;
    }

    @include mobilePortrait {
      display: block !important;
    }
    
    @include tablet {
      display: none !important;
    }

    @include desktop {
      display: none !important;
    }
  }
