.loading-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: none;
    background-color: rgba(28, 30, 35, 0.16);
  
    &.active {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    
  }
  